<template>
  <v-container
    :style="backgroundStyle"
    class="mx-0 pa-sm-8 d-flex align-start"
    fill-height
    fluid
  >
    <template v-if="isOnlyDashboard">
      <v-row
        class="d-flex justify-center"
      >
        <v-col cols="12" md="10" class="pa-0">
          <v-card
            class="mx-xs-0 mx-sm-auto px-xs-0 px-md-4 pb-4"
            max-width="100%"
            outlined
          >
            <CardToolbar
              :icon="CALENDAR_ICON"
              class="mt-4"
              title="Upcoming Events"
            />

            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span v-if="upcomingEventsInitialized">There are <strong class="primary--text">{{ numberOfUpcomingEvents }}</strong> upcoming events within the next two weeks.</span>
                  <span v-else>Checking for upcoming events...</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <template v-if="numberOfUpcomingEvents > 0">
                    <v-list-item v-for="event in upcomingEvents" :key="event.id" three-line>
                      <v-list-item-icon>
                        <TooltipIcon
                          bottom
                          :color="event.color"
                          :icon-name="UPCOMING_EVENT_ICON"
                          :text="`${event.event_group_type} / ${event.event_type}`"
                        />
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ event.name }}</v-list-item-title>
                        <v-list-item-subtitle class="primary--text">
                          {{ getDay(event.date) }}, {{ event.date }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          {{ event.description }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <span v-else>Add events from within the <span class="primary--text" @click="pushTo(MAILING_SCHEDULE_ROUTE)" style="cursor: pointer;">Mailing Schedule</span>.</span>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

          </v-card>
        </v-col>

        <v-col cols="12" md="10" class="pa-0 mt-4">
          <v-card
            class="mx-xs-0 mx-sm-auto px-xs-0 px-md-4 pb-4"
            max-width="100%"
            outlined
          >
            <CardToolbar
              :icon="DASHBOARD_ICON"
              class="mt-4"
              title="Dashboard"
            />

            <v-card
              class="my-4 mx-4"
              flat
            >
              <v-row
                v-for="(group) in navigationGroups" :key="group.title"
                class="d-flex justify-center"
              >
                <v-col cols="12" class="secondary--text text-h6 d-flex justify-center">{{ group.title }} Tools</v-col>

                <v-col 
                  v-for="(link, i) in group.links" :key="i"
                  @click="pushTo(navigationLink(link).route)"
                  @mouseover="hoverLink = link"
                  cols="12" md="3"
                  class="pa-0 my-2 mx-0 mx-md-4"
                  style="cursor: pointer;"
                  v-ripple
                >
                  <v-card
                    class="mx-xs-0 mx-sm-auto px-xs-0 px-md-4 pb-2"
                    :hover="hoverLink === link"
                    outlined
                  >
                    <v-card-title class="d-flex justify-center">
                      <v-icon class="primary--text" left>{{ navigationLink(link).icon }}</v-icon>
                      <span class="primary--text">{{ navigationLink(link).text }}</span>
                    </v-card-title>
                    <v-card-text class="d-flex justify-center">
                      {{ navigationLink(link).description }}
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-card>
        </v-col>
      </v-row>

    </template>

    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { ICONS, ROUTES } from '../constants'
import { getDay } from '../services/utility.service'
import { push } from '../router'

export default {
  components: {
    CardToolbar: () => import('../components/layout/toolbars/CardToolbar.vue'),
    TooltipIcon: () => import('../components/input/TooltipIcon.vue')
  },
  data: () => ({
    isOnlyDashboard: false,
    upcomingEvents: [],
    upcomingEventsInitialized: false,
    numberOfUpcomingEvents: 0,
    hoverLink: -1, // the navigation link currently being hovered.
  }),
  computed: {
    ...mapGetters({
      backgroundStyle: 'sitemap/getBackgroundStyle',
      navigationGroups: 'sitemap/getNavigationGroups',
      navigationLink: 'sitemap/getNavigationLink'
    }),
  },
  methods: {
    ...mapActions({
      fetchUser: 'user/fetchUser',
      fetchUpcomingEvents: 'calendarEvent/fetchUpcomingEvents',
      errorMessage: 'message/errorMessage',
    }),
    ...mapMutations({
      setBackground: 'sitemap/setBackground',
    }),
    initTemplateConstants () {
      this.CALENDAR_ICON = ICONS.CALENDAR
      this.DASHBOARD_ICON = ICONS.MONITOR
      this.UPCOMING_EVENT_ICON = ICONS.CALENDAR_UPCOMING_EVENT
    },
    getDay (date) {
      return getDay(date)
    },
    pushTo (link) {
      push(link)
    }
  },
  watch: {
    $route: {
      handler: function(to, from) {
        this.isOnlyDashboard = to.path === ROUTES.DASHBOARD
      },
      deep: true
    }
  },
  mounted () {
    this.isOnlyDashboard = this.$route.path === ROUTES.DASHBOARD
    this.MAILING_SCHEDULE_ROUTE = ROUTES.MAILING_SCHEDULE
  },
  async created() {
    this.initTemplateConstants()
    this.setBackground()

    this.fetchUpcomingEvents()
      .then(events => {
        this.upcomingEvents = events
        this.numberOfUpcomingEvents = this.upcomingEvents.length
      })
      .catch(error => {
        this.errorMessage(`Failed to fetch upcoming events: ${error}`)
      })
      .finally(() => {
        this.upcomingEventsInitialized = true
      })

    await this.fetchUser()
  },
}
</script>